/* eslint-disable react/prop-types */
import '../styles/globals.scss';
import store from '../store/store';
import { Provider } from 'react-redux';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import axios from 'axios';
import TagManager from 'react-gtm-module';
import { TYPE_CONSTANT } from '../constant/Web_constant';
import { useRouter } from 'next/router';
import Loading from '../components/Shared/Loading/Loading';
import Cookies from 'js-cookie';

import Integrations from '../integrations/integrations';
import NoSSRIntegrations from '../integrations/NoSSRIntegrations';

function MyApp({ Component, pageProps, err }) {
  const router = useRouter();

  // set draft  value:
  const is_draft = router?.query?.draft
    ? true
    : false || Cookies.get('is_draft') === 'true'
    ? true
    : false;

  TYPE_CONSTANT.IS_DRAFT = router?.query?.draft
    ? true
    : false || Cookies.get('is_draft') === 'true'
    ? true
    : false;

  //
  const [is_connected, setIs_connected] = useState(false);
  const [is_onboarding, setIs_onboarding] = useState(false);
  const [is_general, setIs_general] = useState(false);
  const [isIntegrations, setIsIntegrations] = useState(false);
  const [start, setStart] = useState(false);
  const [favicon, setFavicon] = useState(false);
  const [hideFooter_note, setHideFooter_note] = useState(false);
  const [primary_font_name, set_primary_font_name] = useState('Montserrat');
  const [search_console, setSearch_console] = useState(null);
  const [all_integrations, setAll_integrations] = useState(null);

  const [loading, setLoading] = useState(false);

  //  api calling
  useEffect(() => {
    axios
      .get('/api')
      .then((res) => {
        setIs_connected(true);
        TYPE_CONSTANT.DOMAIN =
          typeof window !== 'undefined' && window.location.host
            ? window.location.host
            : '';
      })
      .catch((error) => {
        setIs_connected(false);
        alert('Domain not found please try later .');
      });
  }, [0]);

  // set in cookies for is_draft
  useEffect(() => {
    let inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);

    console.log('is_draft: ', is_draft);
    is_draft &&
      Cookies.set(`is_draft`, true, {
        expires: inOneHour,
      });
  }, [is_draft]);

  //loading for changing url
  useEffect(() => {
    const handleStart = (url) => {
      url !== router.pathname ? setLoading(true) : setLoading(false);
    };
    const handleComplete = (url) => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
  }, [router]);

  useEffect(() => {
    if (is_connected) {
      // set configs
      axios.get('/api/configs/payments').then((res) => {
        TYPE_CONSTANT.PAYMENT_CONFIGS = res?.data.configs || '';
      });
      axios.get('/api/configs/listings').then((res) => {
        TYPE_CONSTANT.LISTINGS_CONFIGS = res?.data.configs || '';
      });
      axios.get('/api/configs/accounts').then((res) => {
        TYPE_CONSTANT.ACCOUNTS_CONFIGS = res?.data.configs || '';
      });
      axios.get('/api/configs/draft_styles').then((res) => {
        TYPE_CONSTANT.DRAFT_STYLES = res?.data.configs.styles || '';
      });

      // // onboarding Configs
      // console.log(TYPE_CONSTANT.DRAFT_STYLES);

      axios
        .get('/api/configs/onboarding')
        .then((res) => {
          if (typeof window !== 'undefined') {
            let root = document.documentElement;

            //   primary color , secondary color , footer color
            const primary_color = is_draft
              ? TYPE_CONSTANT.DRAFT_STYLES?.onboarding?.app_color_primary ||
                '#000'
              : res.data.configs.app_color_primary || '#000';
            const secondary_color = is_draft
              ? TYPE_CONSTANT.DRAFT_STYLES?.onboarding?.app_color_secondary ||
                '#000'
              : res.data.configs.app_color_secondary || '#000';
            const footer_color = is_draft
              ? TYPE_CONSTANT.DRAFT_STYLES?.onboarding?.bg_footer_color ||
                '#FFF'
              : res.data.configs.bg_footer_color || '#FFF';

            root.style.setProperty('--footer_color', footer_color);
            root.style.setProperty('--primary_color', primary_color);
            root.style.setProperty('--secondary_color', secondary_color);
            localStorage.setItem(
              'onboarding_configs',
              is_draft
                ? JSON.stringify({
                    ...res.data.configs,
                    ...TYPE_CONSTANT.DRAFT_STYLES?.onboarding,
                  })
                : JSON.stringify(res.data.configs)
            );

            setIs_onboarding(true);
          }
        })
        .catch((error) => {
          setIs_onboarding(false);
        });

      // General Configs
      axios
        .get('/api/configs/general')
        .then((res) => {
          if (typeof window !== 'undefined') {
            let root = document.documentElement;
            //fonts set
            const primary_font = is_draft
              ? TYPE_CONSTANT.DRAFT_STYLES?.general?.web_primary_font ||
                primary_font_name
              : res.data.configs.web_primary_font || primary_font_name;
            const title_font = is_draft
              ? TYPE_CONSTANT.DRAFT_STYLES?.general?.web_font_title ||
                primary_font_name
              : res.data.configs.web_font_title || primary_font_name;
            const body_font = is_draft
              ? TYPE_CONSTANT.DRAFT_STYLES?.general?.web_font_body ||
                primary_font_name
              : res.data.configs.web_font_body || primary_font_name;

            root.style.setProperty('--primary_font', primary_font);
            set_primary_font_name(primary_font);
            root.style.setProperty('--title_font', title_font);
            set_primary_font_name(primary_font);
            root.style.setProperty('--body_font', body_font);
            set_primary_font_name(primary_font);

            //   marketplace type
            localStorage.setItem('MARKETPLACE_MODULES', res.data.configs.type);
            TYPE_CONSTANT.MARKETPLACE_MODULES = res.data.configs?.type;
            //   marketplace module
            localStorage.setItem(
              'MARKETPLACE_FLAVOURS',
              res.data.configs?.sub_type
            );
            TYPE_CONSTANT.MARKETPLACE_FLAVOURS = res.data.configs?.sub_type;

            //Set  theme number
            localStorage.setItem('THEME', res.data.configs?.theme);
            TYPE_CONSTANT.THEME = res.data.configs?.theme;
            TYPE_CONSTANT.GENERAL_CONFIGS = is_draft
              ? TYPE_CONSTANT.DRAFT_STYLES?.general
              : res.data.configs;

            //   favicon ,logo,footer note
            setFavicon(
              is_draft
                ? TYPE_CONSTANT.DRAFT_STYLES?.general?.web_icon
                : res?.data?.configs?.web_icon
            );

            // logo
            localStorage.setItem(
              'logo',
              is_draft
                ? TYPE_CONSTANT.DRAFT_STYLES?.general?.web_logo
                : res?.data?.configs?.web_logo
            );

            //set  footer note
            setHideFooter_note(res?.data?.configs?.hide_tradly_footer_note);

            //set general configs in local storage
            localStorage.setItem(
              'general_configs',
              is_draft
                ? JSON.stringify({
                    ...res.data.configs,
                    ...TYPE_CONSTANT.DRAFT_STYLES?.general,
                  })
                : JSON.stringify(res.data.configs)
            );
            setIs_general(true);
          }
        })
        .catch((error) => {
          setIs_general(false);
        });

      // integrations config
      axios
        .get('/api/configs/integrations')

        .then((res) => {
          if (typeof window !== 'undefined') {
            setAll_integrations(res.data.configs);
            TYPE_CONSTANT.ALL_INTEGRATIONS = is_draft
              ? TYPE_CONSTANT.DRAFT_STYLES?.integrations
              : res.data.configs;
            if (res.data.configs.gtm) {
              TagManager.initialize({
                gtmId: `GTM-${res.data.configs?.google_tag_manager?.gtm}`,
              });
            }
            if (res.data.configs?.searchconsole) {
              setSearch_console(res.data.configs?.searchconsole);
            }

            setIsIntegrations(true);
          }
        })
        .catch((error) => {
          setIsIntegrations(false);
        });

      // SEO Configs
      axios.get('/api/configs/seo').then((res) => {
        const { configs } = res?.data;
        TYPE_CONSTANT.META_TITLE = is_draft
          ? TYPE_CONSTANT.DRAFT_STYLES?.seo?.meta_title || ''
          : configs?.meta_title || '';
        TYPE_CONSTANT.META_DESCRIPTIONS = is_draft
          ? TYPE_CONSTANT.DRAFT_STYLES?.seo?.meta_description
          : configs?.meta_description || '';
        TYPE_CONSTANT.META_ACCOUNT_TITLE = is_draft
          ? TYPE_CONSTANT.DRAFT_STYLES?.seo?.meta_account_title || ''
          : configs?.meta_account_title || '';
        TYPE_CONSTANT.META_LISTING_TITLE = is_draft
          ? TYPE_CONSTANT.DRAFT_STYLES?.seo?.meta_listing_title || ''
          : configs?.meta_listing_title || '';
        TYPE_CONSTANT.META_LISTING_DESCRIPTION = is_draft
          ? TYPE_CONSTANT.DRAFT_STYLES?.seo?.meta_listing_description || ''
          : configs?.meta_listing_description || '';
        TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE = is_draft
          ? TYPE_CONSTANT.DRAFT_STYLES?.seo?.meta_listing_category_title || ''
          : configs?.meta_listing_category_title || '';
        TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION = is_draft
          ? TYPE_CONSTANT.DRAFT_STYLES?.seo
              ?.meta_listing_category_description || ''
          : configs?.meta_listing_category_description || '';
      });
    }
  }, [is_connected]);

  useEffect(() => {
    if (is_onboarding && is_general && isIntegrations) {
      setStart(true);
    } else {
      setStart(false);
    }
  }, [is_onboarding, is_general, isIntegrations]);

  return (
    is_connected &&
    start && (
      <>
        <Head>
          <link rel="icon" href={favicon} />
          <link
            href={`https://fonts.googleapis.com/css2?family=${primary_font_name}&display=optional`}
            rel="stylesheet"
          />
        </Head>
        <Integrations all_integrations={all_integrations} />
        <Provider store={store}>
          <Loading loading={loading} />
          <Component {...pageProps} err={err} />
          <NoSSRIntegrations all_integrations={all_integrations} />

          {/* tradly  footer note*/}
          {!hideFooter_note && (
            <div
              className=" fixed bottom-5 left-5 z-50 shadow px-2 py-2 flex items-center gap-2 rounded-button bg-black cursor-pointer"
              onClick={() =>
                window.open(
                  'https://tradly.app?utm_source=plg&utm_medium=built_button'
                )
              }
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 126 126"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M63 0C97.7939 0 126 28.2061 126 63C126 97.7939 97.7939 126 63 126C28.2061 126 0 97.7939 0 63C0 28.2061 28.2061 0 63 0Z"
                  fill="url(#paint0_linear)"
                ></path>
                <path
                  opacity="0.5"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M63 119C93.9279 119 119 93.9279 119 63C119 32.0721 93.9279 7 63 7C32.0721 7 7 32.0721 7 63C7 93.9279 32.0721 119 63 119Z"
                  stroke="white"
                  strokeWidth="1.4"
                ></path>
                <path
                  opacity="0.5"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M63 105C86.196 105 105 86.196 105 63C105 39.804 86.196 21 63 21C39.804 21 21 39.804 21 63C21 86.196 39.804 105 63 105Z"
                  stroke="white"
                  strokeWidth="1.4"
                ></path>
                <path
                  d="M108.282 44.2442C105.799 38.2551 102.162 32.8652 97.6482 28.3518C88.7809 19.4845 76.5309 14 63 14C49.469 14 37.219 19.4845 28.3517 28.3518C23.8383 32.8652 20.2012 38.2551 17.7178 44.2442"
                  stroke="white"
                  strokeWidth="15.4"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M63.0001 14.0001V111.222"
                  stroke="white"
                  strokeWidth="15.4"
                  strokeLinecap="round"
                ></path>
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="126"
                    y1="0"
                    x2="126"
                    y2="126"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2BDBC0"></stop>
                    <stop offset="1" stopColor="#13B58C"></stop>
                  </linearGradient>
                </defs>
              </svg>
              <p className="text-sm font-semibold text-white  font-Inter-var">
                Built with <span className=" text-[#55d4a3] ">Tradly</span>
              </p>
            </div>
          )}
        </Provider>
      </>
    )
  );
}

export default MyApp;
