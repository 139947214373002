import React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import tradly from 'tradly';

const Integrations = ({ all_integrations }) => {
  return (
    <Head>
      {/* <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `}}> */}
      {/* Search console */}
      {all_integrations?.searchconsole && (
        <meta
          name="google-site-verification"
          content={
            all_integrations?.searchconsole?.google_site_verification_content
          }
        />
      )}

      {/* hubspot */}
      {all_integrations?.hubspot && (
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src={`//js.hs-scripts.com/${all_integrations?.hubspot?.tracking_code}.js`}
        ></script>
      )}

      {/* hotjar */}
      {all_integrations?.hotjar && (
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${all_integrations?.hotjar?.hotjar_siteid},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        />
      )}

      {/* Freshworks marketting */}
      {all_integrations?.freshworks_marketing && (
        <script
          dangerouslySetInnerHTML={{
            __html: `(function() {
 var script = document.createElement('script');
 script.type = 'text/javascript';
 script.src = 'https://in.fw-cdn.com/${all_integrations?.freshworks_marketing?.freshworks_pixel_id}.js';  
 script.setAttribute('chat', 'true');
 document.getElementsByTagName('head')[0].appendChild(script);
 })();`,
          }}
        />
      )}

      {/*
       mailchimp*/}
      {all_integrations?.mailchimp && (
        <script
          id="mcjs"
          dangerouslySetInnerHTML={{
            __html: `!function(c,h,i,m,p)
        {
          ((m = c.createElement(h)),
          (p = c.getElementsByTagName(h)[0]),
          (m.async = 1),
          (m.src = i),
          p.parentNode.insertBefore(m, p))
        }
        (document,"script",${all_integrations?.mailchimp?.mailchimp_url});`,
          }}
        />
      )}

      {/* <!-- BEGIN PRIVY ASYNCHRONOUS WIDGET CODE --> */}

      {all_integrations?.privy && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `  var _d_site = _d_site ||"${all_integrations.privy?.account_identifier}";
   (function(p, r, i, v, y) {
     p[i] = p[i] || function() { (p[i].q = p[i].q || []).push(arguments) };
     v = r.createElement('script'); v.async = 1; v.src = 'https://widget.privy.com/assets/widget.js';
     y = r.getElementsByTagName('script')[0]; y.parentNode.insertBefore(v, y);
   })(window, document, 'Privy');`,
          }}
        />
      )}

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {all_integrations?.googleanalytics && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${all_integrations?.googleanalytics?.ga_tracking_id}`}
        />
      )}

      {all_integrations?.googleanalytics && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${all_integrations?.googleanalytics?.ga_tracking_id}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
      )}

      {/* heap */}
      {all_integrations?.heap && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
heap.load(${all_integrations?.heap.app_id});`,
          }}
        />
      )}

      {/* Tidio */}
      {all_integrations?.tidio && (
        <script
          src={`//code.tidio.co/${all_integrations?.tidio?.public_key}.js`}
          async
        ></script>
      )}

      {all_integrations?.Usetiful && (
        <script
          dangerouslySetInnerHTML={{
            __html: `(function (w, d, s) {
    var a = d.getElementsByTagName('head')[0];
    var r = d.createElement('script');
    r.async = 1;
    r.src = s;
    r.setAttribute('id', 'usetifulScript');
    r.dataset.token = '${all_integrations?.Usetiful?.dataset_token}';
                        a.appendChild(r);
  })(window, document, "https://www.usetiful.com/dist/usetiful.js");
             `,
          }}
        />
      )}

     
    </Head>
  );
};

export default Integrations;
