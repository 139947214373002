export var TYPE_CONSTANT = {
  DOMAIN: '',
  IS_DRAFT: false,
  MARKETPLACE_MODULES: '',
  MARKETPLACE_FLAVOURS: '',
  ALL_INTEGRATIONS: '',
  AUTH_KEY: '',
  THEME: '',
  AUTH_KEY: '',
  META_TITLE: '',
  META_DESCRIPTIONS: '',
  META_ACCOUNT_TITLE: '',
  META_ACCOUNT_DESCRIPTIONS: '',
  META_LISTING_TITLE: '',
  META_LISTING_DESCRIPTION: '',
  META_LISTING_CATEGORY_TITLE: '',
  META_LISTING_CATEGORY_DESCRIPTION: '',
  PAYMENT_CONFIGS: '',
  LISTINGS_CONFIGS: '',
  ACCOUNTS_CONFIGS: '',
  DRAFT_STYLES: '',
  GENERAL_CONFIGS: '',
};
