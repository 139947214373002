import React from 'react';
import dynamic from 'next/dynamic';
import Whatsapp_button from './Whatsapp_button';

const CrispWithNoSSR = dynamic(() => import('./crisp'), {
  ssr: false,
});
const IntercomWithNoSSR = dynamic(() => import('./intercom'), {
  ssr: false,
});
const FacebookWithNoSSR = dynamic(() => import('./facebook'), {
  ssr: false,
});
const FacebookPixelWithNoSSR = dynamic(() => import('./facebook_pixel'), {
  ssr: false,
});
const Drift = dynamic(() => import('./drift'), {
  ssr: false,
});

const NoSSRIntegrations = ({ all_integrations }) => {
  return (
    <div>
      {/* Crisp */}
      {all_integrations?.crisp && (
        <CrispWithNoSSR
          CRISP_WEBSITE_ID={all_integrations?.crisp.crisp_website_id}
        />
      )}

      {/* intercom */}
      {all_integrations?.intercom && (
        <IntercomWithNoSSR app_id={all_integrations?.intercom.appId} />
      )}

      {/* facebook */}
      {all_integrations?.facebook_messenger && (
        <FacebookWithNoSSR
          page_id={all_integrations?.facebook_messenger?.page_id}
        />
      )}
      {/* facebook  Pixel*/}
      {all_integrations?.facebook_pixel && (
        <FacebookPixelWithNoSSR
          FB_PIXEL_ID={all_integrations?.facebook_pixel?.fb_pixel_id}
        />
      )}
      {all_integrations?.drift && (
        <Drift drift_load_key={all_integrations?.drift?.drift_load_key} />
      )}

      {all_integrations?.whatsapp && (
        <Whatsapp_button
          phone_number={all_integrations?.whatsapp?.phone_number}
          default_text={all_integrations?.whatsapp?.default_text || ''}
          position={all_integrations?.whatsapp?.button_position}
        />
      )}
    </div>
  );
};

export default NoSSRIntegrations;
